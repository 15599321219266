.custom-footer {
    position: fixed;
    bottom: 1.5%;
    width: 600px;
    max-width: 100%;
    border-radius: 100px;
    margin: 0 auto;
    background-color: #f8f9fa;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    height: 50px;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.9s ease-in-out forwards 0.5s;
  }
  

.footer-content {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  margin-top: 2%;
}

.footer-content a {
    color: forestgreen;
    text-decoration: none;
}

body {
    padding-bottom: 60px;
  }
  
  .fllllll {
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
