.custom-navbar {
  width: 600px;
  border-radius: 100px;
  background-color: #f8f9fa;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  margin-top: 2%;
  height: 50px;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadeIn 0.9s ease-in-out forwards 0.5s;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.avv {
  padding-left: 1em;
  cursor: pointer;
}

.navbar-brand img {
  object-fit: contain;
  height: 65px;
  padding-top: 5px;
}

.nav-link {
  font-weight: 500;
  color: #666666;
  padding: 0 15px;
  text-decoration: none;
  cursor: pointer;
}

.nav-link:hover {
  color: #ff4500;
  text-decoration: none;
}

.nav-link.active {
  color: #228b22;
  font-weight: bold;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #f8f9fa;
}

.navbarNav {
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 1.3em;
  padding-top: 1em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-item-custom {
  color: #333;
  background-color: #f8f9fa;
  padding: 10px;
  transition: background-color 0.2s ease;
}

.dropdown-item-custom:hover {
  color: orangered;
}

.dropdown-item-custom:active {
  background-color: forestgreen;
  color: white;
}
