.perf_container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 365px;
  box-sizing: border-box;
  overflow-y: none;
}

.perf_posts-container {
  flex: 2;
  overflow-y: auto;
  margin-bottom: 10px;
  height: 300px;
}

.perf_post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.perf_post-date {
  font-size: 0.9em;
  color: #888;
  margin-bottom: 5px;
}

.perf_post-text {
  flex-grow: 1;
  font-size: 1.1em;
}

.perf_delete-button {
  margin-top: 10px;
  padding: 5px;
  border: none;
  background-color: #ff6666;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
  align-self: flex-end;
}

.perf_input-container {
  display: flex;
  align-items: center;
  background-color: #fff;
  position: relative;
  flex-shrink: 0;
  bottom: -3%;
}

.perf_text-area {
  width: 90%;
  height: 70px;
  padding: 10px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.perf_post-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
