.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  border-radius: 8px !important;
  width: 80%;
  max-width: 800px;
  min-height: 570px;
  max-height: 580px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
  background: #f9f9f9;
  position: relative;
  flex-shrink: 0;
}

.modal-tabs {
  display: flex;
  border-bottom: 2px solid #ddd;
  background: #f9f9f9;
  position: relative;
  flex-shrink: 0;
}

.tab-button {
  flex: 1;
  padding: 10px;
  border: none;
  background: #f4f4f4;
  cursor: pointer;
  text-align: center;
}

.tab-button.active {
  background: #fff;
  border-bottom: 2px solid #228b22;
}

.modal-body {
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 17px;
  border-top: 1px solid #ddd;
  background: #f9f9f9;
  position: relative;
  flex-shrink: 0;
}

.save-btn {
  background-color: #228b22;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #006400;
}

.discard-btn {
  background-color: #ff4500;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.discard-btn:hover {
  background-color: #c7151c;
}
