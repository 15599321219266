.flexed input {
  border: none;
  margin-left: -10px;
}

.flexed {
  display: flex;
  flex-direction: row;
}

.butttton {
  font-size: medium;
  cursor: pointer;
}

.profile-pic-container button {
  margin-top: -50%;
}

.img-con {
  margin-top: -12%;
}

.name-update-section {
  display: flex;
  align-items: center;
  gap: 10px;
}

.name-update-section input {
  flex: 1;
  padding: 10px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.name-update-section button.add-button {
  padding: 10px 20px;
  background-color: forestgreen;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.name-update-section button.add-button:hover {
  background-color: #45a049;
}
