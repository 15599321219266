.flxdetails {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.llp_details {
    margin: 0;
}

.rrp_details {
    display: flex;
    gap: 10px;
}

.ed-button {
    background-color: dodgerblue;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.dell-button {
    background-color: darkred;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.confirm-button {
    background-color: forestgreen;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.cancel-button {
    background-color: slategray;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}