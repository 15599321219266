.company__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.employee__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
}

label {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  color: #333;
  font-size: 14px;
}

input {
  margin-top: 8px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

input:focus {
  outline: none;
  border-color: #228b22;
}

.flexform {
  display: flex;
  gap: 50px;
}

.leftform {
  width: 50%;
}

.rightform {
  width: 50%;
}

.leftform label {
  margin-bottom: 8px;
}

.rightform label {
  margin-bottom: 8px;
}

.form-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.select {

  select {
    font-size: 14px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    height: 100%;
    width: 100%;
    cursor: pointer;
    outline: none;
    padding-right: 35px;
    padding-left: 15px;
    border: 1px solid #228b22;

    -moz-appearance: none;

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    -webkit-appearance: none;

    &::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: #63ffb4;
    }
  }
}

.tag-cloud {
  background-color: #228b22;
  padding: 3px 7px;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  font-size: 12px;
  display: inline-block;
  margin-bottom: 5px;
}

@media all and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
  .select {
    select {
      padding-right: 0;
    }

    &:after,
    &:before {
      display: none;
    }
  }
}
