.llright {
  float: right;
  margin-top: -37%;
}

.image-container {
  position: relative;
  display: inline-block;
}

.company-avatar {
  transition: filter 0.3s ease;
}

.blurred {
  filter: blur(3px);
}

.edit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.edit-icon {
  cursor: pointer;
  color: white;
}

.hidden-input {
  display: none;
}
