.fupload {
    display: flex;
    flex-direction: column;
  }
  
  .leftupload {
    width: 35%;
    margin: 0 auto;
    justify-content: center;
  }
  
  .rightupload {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
    height: 150px;
    font-size: 10px;
  }
  
  .file-container {
    position: relative;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
  }
  
  .file-preview {
    width: 100%;
    height: auto;
    max-height: 110px;
    object-fit: contain;
  }
  
  .file-name {
    margin-top: 10px;
  }
  
  .download-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: forestgreen;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: none;
  }
  
  .del-button {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: orangered;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: none;
  }
  
  .file-container:hover .download-button {
    display: block;
  }
  
  .file-container:hover .del-button {
    display: block;
  }
  