.company-page {
  display: flex;
  gap: 20px;
  margin-top: 4em;
}

.column {
  flex: 1;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.company-list,
.employee-list {
  flex: 0 0 300px;
}

.company-details {
  flex: 0 0 500px;
  width: 40px;
}

.company-page h3 {
  font-size: 1.5em;
  color: #333;
}

.company-page ul {
  list-style-type: none;
  padding: 0;
}

.company-page ul li {
  padding: 10px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
  cursor: pointer;
}

.company-page ul li:hover {
  background-color: #ccc;
}

.company-container {
  padding: 20px;
}

.company-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.company-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 20px;
}

.company-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-height: 150px;
  width: 600px;
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out forwards 0.5s;
}

.action-button {
  background-color: #228b22;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.action-button:hover {
  background-color: #ff4500;
}

.company-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.zero-count {
  color: #ff4500;
}

.non-zero-count {
  color: #228b22;
}
