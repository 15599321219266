.add-button {
    background-color: forestgreen;
    color: white;
    border-radius: 8px;
    padding: 5px 10px;
    border: none;
}

.add-button:hover {
    background-color: darkgreen;
}

.company-page {
  display: flex;
  gap: 20px;
  margin-top: 4em;
}

.column {
  flex: 1;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.company-list,
.employee-details {
  flex: 0 0 300px;
}

.company-details {
  flex-grow: 1;
}

.company-list h3 {
  font-size: 1.5em;
  color: #333;
}

.company-list ul {
  list-style-type: none;
  padding: 0;
  min-width: 300px;
}

.company-list ul li {
  padding: 10px;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  cursor: pointer;
}

.company-list ul li:hover {
  background-color: #ccc;
}

.edit-company-button {
  background-color: #228B22 !important;
  color: white !important;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  float: right;
  margin-top: -6px !important;
}

.edit-company-button:hover {
  background-color: #FF4500;
}
