.employee__page {
  display: flex;
  gap: 20px;
  margin-top: 4em;
}

.column {
  flex: 1;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.employee__list,
.employee-list {
  flex: 0 0 300px;
}

.employee__details {
  flex: 0 0 500px;
  width: 40px;
}

.employee__page h3 {
  font-size: 1.5em;
  color: #333;
}

.employee__page ul {
  list-style-type:decimal !important;
  padding: 0;
}

.employee__page ul li {
  padding: 10px;
  background-color: #e0e0e0;
  margin-bottom: 10px;
  cursor: pointer;
}

.employee__page ul li:hover {
  background-color: #ccc;
}

.employee__header {
  text-align: center;
  margin-bottom: 30px;
}

.employee__container {
  padding: 20px;
}

.colos {
  margin-top: 15px;
}

.employee__content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
}

.employee__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
  gap: 20px;
}

.employee__card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  min-height: 150px;
}

.employee__card ul li {
  padding: 10px;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  cursor: pointer;
}

.employee__card ul li:hover {
  background-color: #ccc;
}

.action-button {
  background-color: #228b22;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.action-button:hover {
  background-color: #ff4500;
}

.employee__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.zero-count {
  color: #ff4500;
}

.non-zero-count {
  color: #228b22;
}

.view-button {
  margin-left: 1em;
}